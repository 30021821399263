'use client'

import cn from '@/utils/style/cn.ts'
import * as TooltipPrimitive from '@radix-ui/react-tooltip'
import type { LucideIcon } from 'lucide-react'
import type { ButtonHTMLAttributes, ReactElement, ReactNode } from 'react'
import { cloneElement } from 'react'
import Button from './Button.tsx'

export const maxWidthMap = {
  sm: 'max-w-sm',
  md: 'max-w-md',
  lg: 'max-w-lg',
  xl: 'max-w-xl',
  '2xl': 'max-w-2xl',
  '3xl': 'max-w-3xl',
  '4xl': 'max-w-4xl',
  '5xl': 'max-w-5xl',
  '6xl': 'max-w-6xl',
  '7xl': 'max-w-7xl',
  '8xl': 'max-w-8xl',
  '9xl': 'max-w-9xl',
  '10xl': 'max-w-10xl',
} as const

export type TooltipProps = (
  | {
      ButtonIconComponent: LucideIcon
      buttonContent?: never
    }
  | {
      ButtonIconComponent?: never
      buttonContent: ReactElement<
        ButtonHTMLAttributes<any> & { 'data-testid': string }
      >
    }
) & {
  tooltipContent: ReactNode
  tooltipMaxWidth?: keyof typeof maxWidthMap
  moreInfoLink?: string
  side?: TooltipPrimitive.TooltipContentProps['side']
  onButtonClick?: () => void
}

export function TooltipProvider(
  props: React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Provider>,
) {
  return <TooltipPrimitive.Provider delayDuration={0} {...props} />
}

export const TooltipRoot = TooltipPrimitive.Root

export const TooltipTrigger = TooltipPrimitive.Trigger

type TooltipContentProps = React.ComponentPropsWithoutRef<
  typeof TooltipPrimitive.Content
> &
  Pick<TooltipProps, 'tooltipContent' | 'tooltipMaxWidth' | 'moreInfoLink'>

export function TooltipContent({
  tooltipContent,
  tooltipMaxWidth = 'md',
  moreInfoLink,
  ...props
}: TooltipContentProps) {
  return (
    <TooltipPrimitive.Portal>
      <TooltipPrimitive.Content
        data-testid="tooltip-content"
        className={cn(
          'z-10 whitespace-normal animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
          maxWidthMap[tooltipMaxWidth],
        )}
        collisionPadding={20}
        sideOffset={5}
        {...props}
      >
        <div className="rounded-md bg-white px-4 py-3 text-left text-gray-900 shadow-md">
          {tooltipContent}
        </div>

        {moreInfoLink && (
          <div className="mt-2 justify-end">
            <a href={moreInfoLink} target="_blank" rel="noopener noreferrer">
              <Button color="blue-link">More Info</Button>
            </a>
          </div>
        )}

        <TooltipPrimitive.Arrow
          width={16}
          height={8}
          className="fill-white"
          style={{
            filter: 'drop-shadow(0 1px 1px rgb(0 0 0 / 0.3)',
          }}
        />
      </TooltipPrimitive.Content>
    </TooltipPrimitive.Portal>
  )
}

/**
 * Simple tooltip component.
 *
 * Leverages @radix-ui/react-tooltip.
 *
 * @link https://www.radix-ui.com/primitives/docs/components/tooltip
 */
export default function Tooltip({
  ButtonIconComponent,
  buttonContent,
  tooltipContent,
  tooltipMaxWidth = 'md',
  moreInfoLink,
  side = 'top',
  onButtonClick,
}: TooltipProps) {
  return (
    <TooltipRoot>
      <TooltipTrigger asChild>
        {ButtonIconComponent ? (
          <button
            tabIndex={-1}
            type="button"
            className="transition hover:brightness-50"
            onClick={onButtonClick}
          >
            <ButtonIconComponent
              absoluteStrokeWidth
              strokeWidth={1.5}
              size={16}
              className="text-gray-400"
            />
          </button>
        ) : (
          cloneElement(buttonContent, {
            type: 'button',
            onClick: onButtonClick,
          })
        )}
      </TooltipTrigger>

      <TooltipContent
        tooltipContent={tooltipContent}
        tooltipMaxWidth={tooltipMaxWidth}
        moreInfoLink={moreInfoLink}
        side={side}
      />
    </TooltipRoot>
  )
}
