import cn from '@/utils/style/cn.ts'
import { ChevronRight } from 'lucide-react'
import type { ButtonHTMLAttributes, ReactNode } from 'react'
import React from 'react'
import type { LoadingSpinnerProps } from './LoadingSpinner.tsx'
import LoadingSpinner from './LoadingSpinner.tsx'

export type ButtonColor =
  | 'blue'
  | 'blue-transparent'
  | 'blue-link'
  | 'green'
  | 'orange'
  | 'orange-link'
  | 'red'
  | 'black'
  | 'white'
  | 'white-transparent'
  | 'white-link'

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  color: ButtonColor
  loading?: boolean
  children: ReactNode
}

const colorMap = {
  blue: 'bg-blue-700 text-white hover:bg-blue-800',
  'blue-transparent':
    'border border-blue-600 bg-transparent text-blue-600 hover:bg-gray-300/50',
  'blue-link': 'bg-transparent text-blue-700 !h-auto !px-0 font-normal',
  green: 'bg-green-600 text-white hover:bg-green-700',
  orange: 'bg-orange-500 text-white hover:bg-orange-600',
  'orange-link': 'bg-transparent text-orange-600 !h-auto !px-0 font-normal',
  red: 'bg-red-600 text-white hover:bg-red-700',
  black: 'bg-gray-900 text-white hover:bg-gray-800',
  white: 'bg-white text-gray-600 hover:bg-gray-200',
  'white-transparent':
    'border border-white bg-transparent text-white hover:bg-gray-300/20',
  'white-link': 'bg-transparent text-white !h-auto !px-0 font-normal',
} as const satisfies Record<ButtonProps['color'], string>

const spinnerColorMap = {
  blue: 'white',
  'blue-transparent': 'gray',
  'blue-link': 'gray',
  green: 'white',
  orange: 'white',
  'orange-link': 'white',
  red: 'white',
  black: 'white',
  white: 'gray',
  'white-transparent': 'gray',
  'white-link': 'gray',
} as const satisfies Record<ButtonProps['color'], LoadingSpinnerProps['color']>

const iconColorMap = {
  'blue-link': 'text-blue-700',
  'orange-link': 'text-orange-600',
  'white-link': 'text-white',
} as const satisfies Partial<Record<ButtonProps['color'], string>>

/**
 * Simple button component of different colors, that handles loading and
 * disabled states.
 */
const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      type,
      color,
      loading = false,
      disabled = false,
      className,
      children,
      ...props
    },
    ref,
  ) => (
    <button
      ref={ref}
      type={type}
      disabled={loading || disabled}
      className={cn(
        colorMap[color],
        className,
        'group relative inline-flex h-9 cursor-pointer items-center justify-center whitespace-nowrap px-6 transition',
        !['blue-link', 'orange-link', 'white-link'].some((c) => c === color) &&
          'rounded-md font-bold',
      )}
      {...props}
    >
      {children}

      {['blue-link', 'orange-link', 'white-link'].some((c) => c === color) && (
        <ChevronRight
          absoluteStrokeWidth
          strokeWidth={1.5}
          size={16}
          className={cn(
            iconColorMap[color as keyof typeof iconColorMap],
            'ml-1 transition group-hover:translate-x-1',
          )}
        />
      )}

      {(loading || disabled) && (
        <div className="absolute inset-[-1px] flex items-center justify-center bg-white/30">
          {loading && <LoadingSpinner color={spinnerColorMap[color]} />}
        </div>
      )}
    </button>
  ),
)

export default Button
